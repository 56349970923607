<template>
    <CallToAction v-for="cta in data.ctas" :model="cta" v-if="data.ctas.length > 0"/>
    <NuxtLink :to="data.link" v-else>
        {{ data.title }}
    </NuxtLink>
</template>

<script lang="ts" setup>

import type { CallToActionModel, NavigationLinkModel } from '~/models';
import CallToAction from './CallToAction.vue';

const props = defineProps<{ model: NavigationLinkModel }>();
const siteStore = useSiteStore();
const data = computed(() => {
    return {
        title: props?.model?.elements?.title?.value,
        link: props?.model?.elements?.link?.value,
        ctas: siteStore.getListAs<CallToActionModel>(props.model.elements.footerLinks.value)
    };
})
</script>